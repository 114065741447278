.BaseInput {
  position: relative;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
}

label {
  font-size: 20px;
  align-self: center;
  padding-right: 10px;
}
input[type='text'],
input[type='password'],
input[type='email'] {
  box-sizing: border-box;
  width: 250px;
  padding: 0 5px;
  margin: 10px 0;
  height: 40px;
  font-size: 19px;
  letter-spacing: 1px;
  background-color: rgb(248, 248, 250);
}

input[type='password'] {
  letter-spacing: 1.6px;
}

.BaseInput img {
  width: auto;
  height: 100%;
  transform: scale(0.4);
  position: absolute;
  right: 0;
}

.BaseInput img:hover {
  cursor: pointer;
}

/* errors */
.ErrorMessage {
  color: red;
  text-align: center;
}

.InputError input {
  border-color: red;
  background-color: #f0e3e3;
}

/* Animation */
/* TODO: Fix Animations */
/* .BaseAnimationContainer {
  display: flex;
  flex: 1;
  justify-content: center;
}

.BaseErrorAnimation-enter {
  color: transparent;
  height: 0;
  opacity: 0;
}
.BaseErrorAnimation-enter-active {
  height: 20px;

  transition: all 200ms ease-out 2000ms;
  -webkit-transition: all 200ms ease-out 2000ms;
  -moz-transition: all 200ms ease-out 2000ms;
}
.BaseErrorAnimation-exit {
  height: 20px;
  opacity: 1;
}
.BaseErrorAnimation-exit-active {
  height: 0;
  opacity: 0;

  transition: all 200ms ease-out;
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
} */
