.Error {
  box-sizing: border-box;
  position: relative;
  padding: 25px;
  margin: 0 0 20px 0;
  height: auto;
  width: 500px;
  border-radius: 6px;
  background-color: rgb(255, 161, 161);
  -moz-box-shadow: 0 2px 3px rgb(213, 50, 50);
  -webkit-box-shadow: 0 2px 3px rgb(213, 50, 50);
  box-shadow: 0 2px 3px rgb(213, 50, 50);
  overflow: hidden;
}

.close {
  position: absolute;
  right: 15px;
  top: 10px;
}

.close:hover {
  cursor: pointer;
}

.ErrorAnimation-enter {
  color: transparent;
  padding: 0;
  height: 0;
  margin: 0;
  opacity: 0;

  transform: scale(0.3);
  -webkit-transform: scale(0.3);
  -moz-transform: scale(0.3);
}
.ErrorAnimation-enter-active {
  padding: 25px;
  margin: 0 0 20px 0;
  height: auto;
  width: 500px;
  opacity: 1;

  transform: scale(1);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);

  transition: all 400ms ease-out;
  -webkit-transition: all 400ms ease-out;
  -moz-transition: all 400ms ease-out;
}
.ErrorAnimation-exit-active {
  color: transparent;
  padding: 0;
  height: 0;
  margin: 0;
  opacity: 0;

  transform: scale(0.4);
  -webkit-transform: scale(0.4);
  -moz-transform: scale(0.4);

  transition: all 400ms ease-out;
  -webkit-transition: all 400ms ease-out;
  -moz-transition: all 400ms ease-out;
}
