.Header {
  background-color: #f1ecfe;
  display: flex;
  flex-direction: row;
  font-size: 25px;
  -moz-box-shadow: 0 2px 3px rgb(190, 206, 222);
  -webkit-box-shadow: 0 2px 3px rgb(190, 206, 222);
  box-shadow: 0 2px 3px rgb(190, 206, 222);
  display: flex;
  align-items: center;
}

.dropdown,
.dropdown-open {
  width: 100%;
  display: flex;
}

.Header > a,
.dropdown > a {
  padding: 20px 20px;
}

.Header > a:hover,
.dropdown > a:hover {
  background-color: #b1bfd7;
  cursor: pointer;
  color: #1b212b;
}
.Header .pushRight {
  margin-left: auto;
}
.Header .hamburger {
  margin-left: auto;
  padding-right: 20px;
  font-size: 40px;
  display: none;
}

@media only screen and (max-width: 750px) {
  .Header .hamburger {
    display: block;
    cursor: pointer;
  }
  .dropdown {
    display: flex;
    opacity: 0;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 71px;
    transition: height 0.5s;
  }
  .dropdown-open {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 150px;
    transition: height 0.5s, opacity 0.3s 0.2s;
  }
  .Header .pushRight {
    margin-left: 0;
  }
}
