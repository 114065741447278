.memberListContainer {
  display: flex;
  flex-direction: column;
}

.showMemberForm {
  width: 200px;
}

.inputContainer {
  width: 300px;
}

.memberList {
  margin-top: 10px;
  padding: 20px;
  border: solid grey 0.5px;
  font-size: 20px;
  background-color: #f9f5ff;
}

.memberList:hover {
  background-color: #f1ecfe;
  cursor: pointer;
}

.memberList:active {
  background-color: #f1ecfe;
}
