@import url(https://fonts.googleapis.com/css?family=Libre+Caslon+Display&display=swap);
.Header {
  background-color: #f1ecfe;
  display: flex;
  flex-direction: row;
  font-size: 25px;
  box-shadow: 0 2px 3px rgb(190, 206, 222);
  display: flex;
  align-items: center;
}

.dropdown,
.dropdown-open {
  width: 100%;
  display: flex;
}

.Header > a,
.dropdown > a {
  padding: 20px 20px;
}

.Header > a:hover,
.dropdown > a:hover {
  background-color: #b1bfd7;
  cursor: pointer;
  color: #1b212b;
}
.Header .pushRight {
  margin-left: auto;
}
.Header .hamburger {
  margin-left: auto;
  padding-right: 20px;
  font-size: 40px;
  display: none;
}

@media only screen and (max-width: 750px) {
  .Header .hamburger {
    display: block;
    cursor: pointer;
  }
  .dropdown {
    display: flex;
    opacity: 0;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 71px;
    transition: height 0.5s;
  }
  .dropdown-open {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 150px;
    transition: height 0.5s, opacity 0.3s 0.2s;
  }
  .Header .pushRight {
    margin-left: 0;
  }
}

.defaultPadding {
  padding: 50px 10%;
}

.BaseInput {
  position: relative;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
}

label {
  font-size: 20px;
  align-self: center;
  padding-right: 10px;
}
input[type='text'],
input[type='password'],
input[type='email'] {
  box-sizing: border-box;
  width: 250px;
  padding: 0 5px;
  margin: 10px 0;
  height: 40px;
  font-size: 19px;
  letter-spacing: 1px;
  background-color: rgb(248, 248, 250);
}

input[type='password'] {
  letter-spacing: 1.6px;
}

.BaseInput img {
  width: auto;
  height: 100%;
  transform: scale(0.4);
  position: absolute;
  right: 0;
}

.BaseInput img:hover {
  cursor: pointer;
}

/* errors */
.ErrorMessage {
  color: red;
  text-align: center;
}

.InputError input {
  border-color: red;
  background-color: #f0e3e3;
}

/* Animation */
/* TODO: Fix Animations */
/* .BaseAnimationContainer {
  display: flex;
  flex: 1;
  justify-content: center;
}

.BaseErrorAnimation-enter {
  color: transparent;
  height: 0;
  opacity: 0;
}
.BaseErrorAnimation-enter-active {
  height: 20px;

  transition: all 200ms ease-out 2000ms;
  -webkit-transition: all 200ms ease-out 2000ms;
  -moz-transition: all 200ms ease-out 2000ms;
}
.BaseErrorAnimation-exit {
  height: 20px;
  opacity: 1;
}
.BaseErrorAnimation-exit-active {
  height: 0;
  opacity: 0;

  transition: all 200ms ease-out;
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
} */

.Form {
  display: inline-flex;
  flex: 1 1;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 20px;
}

.FormContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: solid 1px rgb(222, 201, 201);
  width: 500px;
  border-radius: 6px;
  background-color: rgb(234, 237, 240);
  box-shadow: 0 2px 3px rgb(190, 206, 222);
}

/**
 * https://github.com/necolas/normalize.css/blob/master/normalize.css
 * Reset Styles
 */

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */

button,
input {
  /* 1 */
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */

button,
select {
  /* 1 */
  text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
  background-color: #998fc7;
  border: none;
}
.delete {
  background-color: #e71d36;
}
.cancel {
  background-color: #4cc9f0;
}

/** 
*  End Reset
*/

button {
  height: 45px;
  font-size: 20px;
  margin: 15px 0;
}

button:hover {
  cursor: pointer;
}

button:active {
  transform: scale(0.99);
}

.Error {
  box-sizing: border-box;
  position: relative;
  padding: 25px;
  margin: 0 0 20px 0;
  height: auto;
  width: 500px;
  border-radius: 6px;
  background-color: rgb(255, 161, 161);
  box-shadow: 0 2px 3px rgb(213, 50, 50);
  overflow: hidden;
}

.close {
  position: absolute;
  right: 15px;
  top: 10px;
}

.close:hover {
  cursor: pointer;
}

.ErrorAnimation-enter {
  color: transparent;
  padding: 0;
  height: 0;
  margin: 0;
  opacity: 0;

  transform: scale(0.3);
  -webkit-transform: scale(0.3);
  -moz-transform: scale(0.3);
}
.ErrorAnimation-enter-active {
  padding: 25px;
  margin: 0 0 20px 0;
  height: auto;
  width: 500px;
  opacity: 1;

  transform: scale(1);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);

  transition: all 400ms ease-out;
  -webkit-transition: all 400ms ease-out;
  -moz-transition: all 400ms ease-out;
}
.ErrorAnimation-exit-active {
  color: transparent;
  padding: 0;
  height: 0;
  margin: 0;
  opacity: 0;

  transform: scale(0.4);
  -webkit-transform: scale(0.4);
  -moz-transform: scale(0.4);

  transition: all 400ms ease-out;
  -webkit-transition: all 400ms ease-out;
  -moz-transition: all 400ms ease-out;
}


.CenteredContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 50px;
}

.AutoCompleteContainer {
  position: relative;
}
.matchContainer {
  position: absolute;
  z-index: 10;
  flex-direction: row;
  width: 250px;
  right: 0;
  margin: -10px 0;
}
.match {
  background-color: rgb(190, 190, 198);
  padding: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.match:hover {
  cursor: pointer;
}

.activeMatch {
  background-color: rgb(170, 170, 178);
}

.match:not(:first-child) {
  border-top: rgb(118, 118, 121) solid 1px;
}
.matchingString {
  font-weight: bold;
}

.relatedMembersContainer {
  box-sizing: border-box;
}
.memberContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: silver solid;
  padding: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.relatedMember {
  padding-right: 5px;
  width: 300px;
}

.removeMember {
  padding: 5px;
  font-weight: bold;
  color: red;
}
.removeMember:hover {
  font-style: italic;
  cursor: pointer;
}
.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.memberButton {
  width: 100%;
  margin: 5px;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: -1;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-backdrop-open {
  z-index: 2;
  opacity: 1;
  background-color: hsla(0, 0%, 0%, 0.5);
}

.modal > .title {
  margin-bottom: 2rem;
}

.modal {
  background-color: white;
  position: absolute;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  padding: 2rem 5rem;
}

.memberListContainer {
  display: flex;
  flex-direction: column;
}

.showMemberForm {
  width: 200px;
}

.inputContainer {
  width: 300px;
}

.memberList {
  margin-top: 10px;
  padding: 20px;
  border: solid grey 0.5px;
  font-size: 20px;
  background-color: #f9f5ff;
}

.memberList:hover {
  background-color: #f1ecfe;
  cursor: pointer;
}

.memberList:active {
  background-color: #f1ecfe;
}

#john-misty {
  position: fixed;
  width: 100%;
  height: 100vh;
}

.text-container {
  fill: #e2d8fd;
  stroke: #998fc7;
  stroke-width: 1px;
}

.text-container:hover {
  cursor: pointer;
}

.connecting-line {
  stroke: #555;
  stroke-width: 2px;
}

.text:hover {
  cursor: pointer;
}

.node--internal text {
  text-shadow: 0 1px 0 #fff, 0 -1px 0 #fff, 1px 0 0 #fff, -1px 0 0 #fff;
}

.link {
  fill: none;
  stroke: red;
  stroke-width: 2px;
}

p {
  font-size: 1.8rem;
  text-align: center;
  padding-top: 2rem;
}
p > a {
  color: #998fc7;
}

.defaultMember {
  font-size: 20px;
}
.defaultMemberLabel {
  font-size: 20px;
  font-weight: 600;
}
.optionButton {
  padding: 5px;
  font-weight: bold;
  color: blue;
  text-decoration: underline;
}
.optionButton:hover {
  font-style: italic;
  cursor: pointer;
}
.update-button {
  margin-left: 10px;
}

.home > h1 {
  text-align: center;
  margin-top: 5rem;
}
.home > p {
  margin-left: 5rem;
  margin-right: 5rem;
}

a {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  color: black;
  text-decoration: none;
}

.App {
  font-family: 'Libre Caslon Display', serif;
  letter-spacing: 1.3px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

