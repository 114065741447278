#john-misty {
  position: fixed;
  width: 100%;
  height: 100vh;
}

.text-container {
  fill: #e2d8fd;
  stroke: #998fc7;
  stroke-width: 1px;
}

.text-container:hover {
  cursor: pointer;
}

.connecting-line {
  stroke: #555;
  stroke-width: 2px;
}

.text:hover {
  cursor: pointer;
}

.node--internal text {
  text-shadow: 0 1px 0 #fff, 0 -1px 0 #fff, 1px 0 0 #fff, -1px 0 0 #fff;
}

.link {
  fill: none;
  stroke: red;
  stroke-width: 2px;
}
