a {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  color: black;
  text-decoration: none;
}

@import url('https://fonts.googleapis.com/css?family=Libre+Caslon+Display&display=swap');

.App {
  font-family: 'Libre Caslon Display', serif;
  letter-spacing: 1.3px;
}
