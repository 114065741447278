/**
 * https://github.com/necolas/normalize.css/blob/master/normalize.css
 * Reset Styles
 */

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */

button,
input {
  /* 1 */
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */

button,
select {
  /* 1 */
  text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
  background-color: #998fc7;
  border: none;
}
.delete {
  background-color: #e71d36;
}
.cancel {
  background-color: #4cc9f0;
}

/** 
*  End Reset
*/

button {
  height: 45px;
  font-size: 20px;
  margin: 15px 0;
}

button:hover {
  cursor: pointer;
}

button:active {
  transform: scale(0.99);
}
