.Form {
  display: inline-flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 20px;
}

.FormContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: solid 1px rgb(222, 201, 201);
  width: 500px;
  border-radius: 6px;
  background-color: rgb(234, 237, 240);
  -moz-box-shadow: 0 2px 3px rgb(190, 206, 222);
  -webkit-box-shadow: 0 2px 3px rgb(190, 206, 222);
  box-shadow: 0 2px 3px rgb(190, 206, 222);
}
