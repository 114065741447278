.relatedMembersContainer {
  box-sizing: border-box;
}
.memberContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: silver solid;
  padding: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.relatedMember {
  padding-right: 5px;
  width: 300px;
}

.removeMember {
  padding: 5px;
  font-weight: bold;
  color: red;
}
.removeMember:hover {
  font-style: italic;
  cursor: pointer;
}
.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.memberButton {
  width: 100%;
  margin: 5px;
}
