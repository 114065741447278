.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: -1;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-backdrop-open {
  z-index: 2;
  opacity: 1;
  background-color: hsla(0, 0%, 0%, 0.5);
}

.modal > .title {
  margin-bottom: 2rem;
}

.modal {
  background-color: white;
  position: absolute;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  padding: 2rem 5rem;
}
