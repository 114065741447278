.AutoCompleteContainer {
  position: relative;
}
.matchContainer {
  position: absolute;
  z-index: 10;
  flex-direction: row;
  width: 250px;
  right: 0;
  margin: -10px 0;
}
.match {
  background-color: rgb(190, 190, 198);
  padding: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.match:hover {
  cursor: pointer;
}

.activeMatch {
  background-color: rgb(170, 170, 178);
}

.match:not(:first-child) {
  border-top: rgb(118, 118, 121) solid 1px;
}
.matchingString {
  font-weight: bold;
}
