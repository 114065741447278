.defaultMember {
  font-size: 20px;
}
.defaultMemberLabel {
  font-size: 20px;
  font-weight: 600;
}
.optionButton {
  padding: 5px;
  font-weight: bold;
  color: blue;
  text-decoration: underline;
}
.optionButton:hover {
  font-style: italic;
  cursor: pointer;
}
.update-button {
  margin-left: 10px;
}
